<template>
  <el-card shadow="never">
    <el-row>
      <div class="date">产能日期：</div>
      <el-date-picker
          v-model="capacityDay"
          type="datetimerange"
          :clearable=false
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']">
      </el-date-picker>
      <el-button type="primary" @click="exportCapacity" :loading="loading" class="btn">导出个人产能</el-button>
    </el-row>
  </el-card>
</template>

<script>
import {betweenDays, currentDay} from "@/utils/common";

export default {
  name: "UserDayCapacity",
  data() {
    return {
      capacityDay: [currentDay() + ' 00:00:00', currentDay() + ' 23:59:59'],
      loading: false
    }
  },
  methods: {
    exportCapacity() {
      if (!this.capacityDay || this.capacityDay.length != 2) {
        return this.$message.error('请选择日期');
      }
      if (betweenDays(this.capacityDay[0], this.capacityDay[1]) > 10) {
        return this.$message.error('日期范围不能超过10天，请分批导出。');
      }

      this.loading = true;
      this.$axios.post('expReport/queryCurrentUserCapacity', {
        beginTime: this.capacityDay[0],
        endTime: this.capacityDay[1],
      }, {timeout: 180000}).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });
    }
  }
}
</script>

<style scoped>
.btn {
  margin-left: 40px;
}

.date {
  display: inline-block;
  width: 120px;
  text-align: right;
}
</style>